import React, { useState, useEffect, useRef } from "react";
import gigStyles from "./gig.module.css";

export default (props) => {
  const { employer, dates, setStyleFromChild, index, styleBit } = props;

  const [hoverRef, isHovered] = useHover(setStyleFromChild, index);

  return (
    <section>
      <h3 className={gigStyles.employer}
        ref={hoverRef}
        style={{
          zIndex: 3,
          opacity: styleBit !== null && styleBit !== index ? `0.1` : `1`,
        }}
      >
        {employer}
      </h3>
      <h6 className={gigStyles.workDates}>
        {isHovered
          ? dates.split(" ").map((date, index) => {
              return (
                <span key={`key_` + index}>
                  {date}
                  <br />
                </span>
              );
            })
          : ""}
      </h6>
    </section>
  );
};

function useHover(setStyleFromChild, index) {
  const [value, setValue] = useState(false);

  const ref = useRef(null);

  const handleMouseOver = () => {
    setValue(true);
    setStyleFromChild(true, index);
  }
  const handleMouseOut = () => {
    setValue(false);
    setStyleFromChild(false, index);
  }

  useEffect(
    () => {
      const node = ref.current;
      if (node) {
        node.addEventListener("mouseover", handleMouseOver);
        node.addEventListener("mouseout", handleMouseOut);

        return () => {
          node.removeEventListener("mouseover", handleMouseOver);
          node.removeEventListener("mouseout", handleMouseOut);
        };
      }
    },
    //[ref.current] // Recall only if ref changes
  );

  return [ref, value];
}
