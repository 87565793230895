import React, { useState, useEffect } from "react";
import { rgb } from "d3";
import { scaleLinear } from "d3-scale";
import Global from "./../components/Global";
import Moniker from "./../components/Moniker";
import PreviousGigs from "./../components/PreviousGigs/PreviousGigs";
import Footer from "./../components/Footer";
import ScrollSection from "./../components/ScrollSection";

export default (props) => {
  const [colVal, setColVal] = useState(true);
  const [compColVal, setCompColVal] = useState(true);
  const handleScroll = () => {
    const position = window.pageYOffset;
    const height = document.documentElement.scrollHeight; //window.outerHeight;

    const c = scaleLinear()
      .domain([0, height])
      .range([`rgb(230, 32, 45`, `rgb(230, 32, 45)`]);

    const cC = scaleLinear().domain([0, height]).range([`#99fffc`, `#99fffc`]);

    setColVal(c(position));
    setCompColVal(rgb(cC(position)).brighter());
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Global bgColor={colVal} compColor={compColVal} bgImage={false} />
      {/* <div className="bg-layer"/> */}
      <div className="container">
        <Moniker compColor={compColVal} />
        <ScrollSection heading="Who am I?" sectionCount="01">
          <p>I am Design Researcher based in Pretoria, South Africa.</p>
          <p>I'm interested in the intersection of design, philosophy and critical theory, and how these can help us build a better world.</p>
        </ScrollSection>
        <ScrollSection heading="Who I've worked with" sectionCount="02">
          <PreviousGigs />
        </ScrollSection>
        <h3 className="section-count">/02</h3>
        <Footer />
      </div>
    </>
  );
};
